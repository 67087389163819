import * as React from "react";
import { isMobile } from "react-device-detect";
import Modal from "../../components/Modal/Modal";
import QRCode from "../../components/QRCode/QRCode";
import WatchFeedModal from "../../components/WatchFeedModal/WatchFeedModal";
import { GLOBAL_WATCH_FEED_VIDEO_COUNT } from "../../utils/constants";
import { useContext } from "react";
import { ModalContext } from "../../components/Modal/ModalContext";

const MainLayout = ({
  children,
  isMobileOverride, showFooter = true,
  isLocation = false
}) => {
  const { closeModal } = useContext(ModalContext);

  return (
    <main className="App">
      {!isMobile && !isMobileOverride ? (
        <Modal content={<QRCode closeModal={closeModal} isLocation={isLocation} />} />
      ) : (
        <WatchFeedModal dataLength={GLOBAL_WATCH_FEED_VIDEO_COUNT} />
      )}
      {children}
    </main>
  );
};

export default MainLayout;
